@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth;
}

.hero {
  display: grid;
  grid-template-columns: 25vw 25vw 25vw 25vw;
  grid-template-rows: 90vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  padding: 0;
}

.blue-bar {
  height: 35vh;
  width: 77vw;
  grid-column: 1 / 4;
  grid-row: 1 / 1;
  z-index: 0;
}

.LOCAL {
  grid-column: 2 / 4;
  grid-row: 1 / 1;
  z-index: 10;
  white-space: nowrap;
}

.typer {
  align-self: center;
  margin-bottom: -8vh;
}

.typerCursor {
  color: #f73e3c;
}
.A920 {
  grid-column: 4 / 4;
  grid-row: 1 / 1;
  min-width: 40vh;
  z-index: 20;
  margin-left: -5vw;
}
.SHADOW {
  grid-column: 4 / 4;
  grid-row: 1 / 1;
  z-index: 25;
  margin-left: -5vw;
  min-width: 40vh;
  margin-top: 3vh;
}

.CONTACT {
  width: 15vw;
  margin-bottom: 15vh;
  grid-column: 2/4;
  grid-row: 1/1;
  justify-self: center;
  align-self: end;
}

.SECTION2 {
  padding: 0;
  margin: 0;
  background: "#1769ff";
  width: 99vw;
  height: 90vh;
  clip-path: polygon(0 13%, 100% 0, 100% 85%, 0% 100%);
}

.youtube {
  width: 840px;
  height: 350px;
}
.whoWeServe {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 8px,
    rgba(47, 80, 167, 0.1) 20px 10px 40px;
  border-radius: 8px;
}
.liftoff {
  width: 5vw;
}

.botNav {
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .hero {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 85vh;
    justify-content: center;
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .blue-bar {
    height: 130vh;
    width: 100vw;
    grid-column: 1 / 1;
    grid-row: 1 /1;
  }
  .SECTION2 {
    width: 100vw;
  }
  .youtube {
    width: 95vw;
    height: 250px;
  }
  .LOCAL {
    grid-column: 1/1;
    grid-row: 1/1;
    align-self: end;
    justify-self: center;
    width: 95vw;
  }
  .A920 {
    grid-column: 1/1;
    grid-row: 1/1;
    max-width: 75vw;
    padding-top: 2vh;
    align-self: start;
    justify-self: center;
  }

  .SHADOW {
    grid-column: 1/1;
    grid-row: 1/1;
    max-width: 75vw;
    padding-top: 11vh;
    align-self: start;
    justify-self: center;
  }
  .CONTACT {
    width: 80vw;
    margin-bottom: -6vh;
    grid-column: 1/1;
    grid-row: 1/1;
    justify-self: center;
    align-self: end;
    z-index: 20;
  }
  .liftoff {
    width: 10vh;
  }

  .botNav {
    visibility: visible;
    width: 98vw;
    height: 8vh;
    z-index: 1000;
  }
}

@media screen and (max-width: 1280px) {
  .typer {
    margin-bottom: -6vh;
  }
}

.restaurants {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #092a66;
}

.container {
  margin: auto;
}

button .active {
  background-color: #eb5e34;
}